import {
  Button,
  Drawer,
  FloatButton,
  Input,
  message,
  Modal,
  notification,
} from "antd";
import {
  TeamOutlined,
  UserAddOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import EventDetailCard from "../Components/EventDetail/EventDetailCard";
import ForumBoard from "../Components/EventDetail/ForumBoard";
import ForumDetail from "../Components/EventDetail/MessageDetail";
import { useEffect, useState } from "react";
import { Forum } from "../types/Forum";
import ForumEditForm from "../Components/Forum/ForumEditForm";
import { EventInfo } from "../types/model";
import OtherMembers from "../Components/EventDetail/OtherMembers";
import { addEventAttendee, getEvent, getEventAttendee } from "../api/api";
import {
  createForum,
  deleteForum,
  getForums,
  updateForum,
  sendBroadCastMessage,
} from "../api/forumService";
import { useAuth } from "../Provider/AuthContext";
import TextArea from "antd/es/input/TextArea";

const EventDetail = () => {
  const [api, contextHolder] = notification.useNotification();
  const { id } = useParams();
  const [selectedForum, setSelectedForum] = useState(-1);
  const [isForumModalOpen, setIsForumModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [forums, setForums] = useState<Forum[]>([]);
  const [modalType, setModalType] = useState<"create" | "update">("create");
  const [event, setEvent] = useState<EventInfo>();
  const [showOtherMembers, setShowOtherMembers] = useState(false);
  const { auth_type, auth, auth_business } = useAuth();
  const [attendance, setAttendance] = useState(false);
  const [broadCastModel, setBroadCastModal] = useState(false);
  const [broadcastMessage, setBroadcastMessage] = useState("");
  const [searchText, setSearchText] = useState("");

  const handleGetEvent = async () => {
    try {
      const response = await getEvent(id);
      if (response.data) setEvent(response.data);
    } catch (err) {
      console.error("Failed to get event:", err);
    }
  };

  const handleGetAttendance = async () => {
    try {
      if (id) {
        const response = await getEventAttendee(id);
        if (response.data) setAttendance(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const attendEvent = async () => {
    try {
      if (id) {
        const response = await addEventAttendee(id);
        if (response.data) setAttendance(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateForum = async () => {
    try {
      if (id) {
        const forum: Forum = {
          title,
          content,
          event_id: parseInt(id),
        };
        const response = await createForum(forum);
        if (response.data) {
          setForums([...forums, response.data]);
          setTitle("");
          setContent("");
          message.success("Success to create the forum.");
        } else {
          message.error("Failed to create the forum.");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateForum = async (value: number) => {
    try {
      const updatedForum: Forum = {
        ...forums[value],
        title,
        content,
      };
      const response = await updateForum(forums[value].forum_id, updatedForum);
      if (response.data) {
        setForums((prev) =>
          prev.map((item, index) =>
            index === value ? { ...item, title, content } : item
          )
        );
        message.success("Success to update the forum.");
      } else {
        message.error("Failed to update the forum.");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteForum = async (value: number) => {
    try {
      if (!forums[value].forum_id) return;
      const response = await deleteForum(forums[value].forum_id);
      if (response.data) {
        setForums((prev) => prev.filter((_, index) => index !== value));
        message.success("Success to delete the forum.");
      } else {
        message.error("Failed to delete the forum.");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleGetForums = async () => {
    try {
      const response = await getForums(id);
      if (response.data) setForums(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleGetEvent();
    handleGetAttendance();
    handleGetForums();
  }, []);

  const forumsFiltered = forums.filter(forum => forum.title?.toLowerCase().includes(searchText.toLowerCase()) || forum.content?.toLowerCase().includes(searchText.toLowerCase()))

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#202A16",
          borderRadius: "16px",
          padding: "40px",
          height: "100%",
        }}
      >
        {contextHolder}
        {event && (
          <EventDetailCard
            data={event}
            onRateChange={(value) => {
              setEvent((prev) => ({ ...prev, rating: value }));
            }}
            onUpdated={() => handleGetEvent()}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 20px",
            flexGrow: "1",
            minWidth: "400px",
          }}
        >
          {auth_type === "user" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "10px",
                padding: "10px 15px",
              }}
            >
              <Input placeholder="Search text here..." value={searchText} onChange={event => setSearchText(event.target.value)} />
              <Button
                type="primary"
                onClick={() => {
                  setIsForumModalOpen(true);
                  setModalType("create");
                }}
              >
                Create Forum
              </Button>
            </div>
          )}
          <ForumBoard
            setSelectedForum={setSelectedForum}
            forumData={forumsFiltered}
            onDelete={handleDeleteForum}
            onUpdate={(value) => {
              const item = forums[value];
              setTitle(item.title);
              setContent(item.content);
              setSelectedForum(value);
              setModalType("update");
              setIsForumModalOpen(true);
            }}
          />
        </div>
        {selectedForum !== -1 && (
          <Drawer
            title={forums[selectedForum].title}
            width={500}
            onClose={() => setSelectedForum(-1)}
            open={!isForumModalOpen}
            style={{ position: "relative" }}
          >
            <ForumDetail data={forums[selectedForum]} />
          </Drawer>
        )}
        <Drawer
          width={500}
          title="Members"
          onClose={() => setShowOtherMembers(false)}
          open={showOtherMembers}
          style={{ position: "relative" }}
        >
          {event && <OtherMembers event={event} />}
        </Drawer>
        <Modal
          open={isForumModalOpen}
          onCancel={() => {
            setSelectedForum(-1);
            setIsForumModalOpen(false);
          }}
          title={
            modalType === "create" ? "Create New Forum" : "Update the forum"
          }
          onOk={() => {
            if (title === "" || content === "") {
              return api.open({
                key: "warning",
                type: "warning",
                message: "Warning",
                description: "Please input data correctly.",
              });
            }
            if (modalType === "create") {
              handleCreateForum();
            } else {
              handleUpdateForum(selectedForum);
              setSelectedForum(-1);
            }
            setIsForumModalOpen(false);
          }}
        >
          <ForumEditForm
            title={title}
            content={content}
            setTitle={setTitle}
            setContent={setContent}
          />
        </Modal>
      </div>
      {(auth_type === "user" ||
        (auth_type === "business" &&
          event?.business_id === auth_business?.business_id)) && (
          <FloatButton
            shape="circle"
            onClick={() => setShowOtherMembers(true)}
            style={{ right: 50 }}
            icon={<TeamOutlined />}
            tooltip="Members"
          />
        )}
      {auth_type === "user" && !attendance && (
        <FloatButton
          shape="circle"
          type="primary"
          onClick={() => attendEvent()}
          style={{ right: 100 }}
          icon={<UserAddOutlined />}
          tooltip="Attend"
        />
      )}
      {(auth_type === "user"
        ? event?.user_id === auth?.user_id
        : event?.business_id === auth_business?.business_id) && (
          <>
            <FloatButton
              shape="circle"
              onClick={() => setBroadCastModal(true)}
              style={{ right: 100 }}
              icon={<MessageOutlined />}
              tooltip="Send Message"
              type="primary"
            />
          </>
        )}
      <Modal
        title="BroadCast"
        open={broadCastModel}
        onCancel={() => setBroadCastModal(false)}
        onOk={() => {
          if (id) sendBroadCastMessage(broadcastMessage, id);
          setBroadCastModal(false);
        }}
      >
        <TextArea
          value={broadcastMessage}
          onChange={(event) => setBroadcastMessage(event.target.value)}
          name="message"
          placeholder="Input the message here..."
          rows={7}
        />
      </Modal>
    </>
  );
};

export default EventDetail;
